import React, { Component } from 'react';

import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

class Setup extends Component {
  // Update Rules
  onChangeHitsToScore = (e) => {
    let rules = { ...this.props.rules };
    let score = e.target.value;

    rules.hitsToScore = score;
    this.props.updateRules(rules);
  };

  onChangeTwentyTo = (e) => {
    let rules = { ...this.props.rules };
    let value = e.target.value;

    rules.twentyTo = value;
    this.props.updateRules(rules);
  };

  onChangeBValue = (e) => {
    let rules = { ...this.props.rules };
    let score = e.target.value;

    rules.bValue = score;
    this.props.updateRules(rules);
  };

  onChangeSwitchRules = (e) => {
    let rules = { ...this.props.rules };
    let key = e.target.name;
    let value = !this.props.rules[key];

    // Update bullseye value
    if (key === 'b25' && value === true) {
      rules.bValue = 50;
    } else if (key === 'b25' && value === false) {
      rules.bValue = 25;
    }

    rules[key] = value;
    this.props.updateRules(rules);
  };

  // Update Game
  onChangeName = (e) => {
    let game = { ...this.props.game };
    let key = e.target.dataset.parent;
    let value = e.target.value;

    game[key].name = value;
    this.props.updateGame(game);
  };

  // Update States then Start the game
  startGame = () => {
    let game = { ...this.props.game };
    let hitsToScore = this.props.rules.hitsToScore;
    let hitsToScoreTrebles = this.props.rules.trebles ? 0 : hitsToScore;
    let hitsToScoredoubles = this.props.rules.doubles ? 0 : hitsToScore;
    let hitsToScoreB25 = this.props.rules.b25 ? 0 : hitsToScore;

    let score = {
      hitsToScore20: 0,
      hitsToScore19: 0,
      hitsToScore18: 0,
      hitsToScore17: 0,
      hitsToScore16: 0,
      hitsToScore15: 0,
      hitsToScore14: 0,
      hitsToScore13: 0,
      hitsToScore12: 0,
      hitsToScore11: 0,
      hitsToScore10: 0,
      hitsToScore9: 0,
      hitsToScore8: 0,
      hitsToScore7: 0,
      hitsToScore6: 0,
      hitsToScore5: 0,
      hitsToScore4: 0,
      hitsToScore3: 0,
      hitsToScore2: 0,
      hitsToScore1: 0,
      hitsToScoreB: 0,
      hitsToScoreTrebles: hitsToScoreTrebles,
      hitsToScoreDoubles: hitsToScoredoubles,
      hitsToScoreB25: hitsToScoreB25,
      totalScore: 0,
    };

    let score2 = {
      hitsToScore20: 0,
      hitsToScore19: 0,
      hitsToScore18: 0,
      hitsToScore17: 0,
      hitsToScore16: 0,
      hitsToScore15: 0,
      hitsToScore14: 0,
      hitsToScore13: 0,
      hitsToScore12: 0,
      hitsToScore11: 0,
      hitsToScore10: 0,
      hitsToScore9: 0,
      hitsToScore8: 0,
      hitsToScore7: 0,
      hitsToScore6: 0,
      hitsToScore5: 0,
      hitsToScore4: 0,
      hitsToScore3: 0,
      hitsToScore2: 0,
      hitsToScore1: 0,
      hitsToScoreB: 0,
      hitsToScoreTrebles: hitsToScoreTrebles,
      hitsToScoreDoubles: hitsToScoredoubles,
      hitsToScoreB25: hitsToScoreB25,
      totalScore: 0,
    };

    // WHY DOESN'T THIS WORK WITH THE SAME OBJECT?!
    game.playerOne.score = score;
    game.playerTwo.score = score2;

    this.props.updateGame(game);
    this.props.updatePage('cricket');
  };

  render() {
    return (
      <div className='mx-auto w-100 p-3'>
        <h1 className='mb-5'>Game Setup</h1>

        <Card className='mb-3 p-3'>
          <Card.Title>Player Names</Card.Title>

          <Card.Body>
            <Form.Group as={Row} controlId='formPlaintextEmail'>
              <Form.Label column sm='3' xs='6' className='text-right'>
                Player One
              </Form.Label>
              <Col sm='9' xs='6' className='text-left'>
                <Form.Control
                  defaultValue={this.props.game.playerOne.name}
                  onChange={this.onChangeName}
                  data-parent='playerOne'
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId='formPlaintextEmail'>
              <Form.Label column sm='3' xs='6' className='text-right'>
                Player Two
              </Form.Label>
              <Col sm='9' xs='6'>
                <Form.Control
                  defaultValue={this.props.game.playerTwo.name}
                  onChange={this.onChangeName}
                  data-parent='playerTwo'
                />
              </Col>
            </Form.Group>
          </Card.Body>
        </Card>

        <Card className='mb-3 p-3'>
          <Card.Title>Rules</Card.Title>

          <Card.Body>
            <Form.Group as={Row} controlId='formPlaintextEmail'>
              <Form.Label column sm='3' xs='6' className='text-right'>
                Hits To Score
              </Form.Label>
              <Col sm='3' xs='4'>
                <Form.Control
                  value={this.props.rules.hitsToScore}
                  onChange={this.onChangeHitsToScore}
                  type='number'
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId='formPlaintextEmail'>
              <Form.Label column sm='3' xs='6' className='text-right'>
                20 to
              </Form.Label>
              <Col sm='3' xs='4'>
                <Form.Control
                  as='select'
                  value={this.props.rules.twentyTo}
                  defaultValue='12'
                  onChange={this.onChangeTwentyTo}
                >
                  <option>15</option>
                  <option>14</option>
                  <option>13</option>
                  <option>12</option>
                  <option>11</option>
                  <option>10</option>
                  <option>9</option>
                  <option>8</option>
                  <option>7</option>
                  <option>6</option>
                  <option>5</option>
                  <option>4</option>
                  <option>3</option>
                  <option>2</option>
                  <option>1</option>
                </Form.Control>
              </Col>
            </Form.Group>

            {this.props.rules.b25 === false && (
              <Form.Group as={Row} controlId='formPlaintextEmail'>
                <Form.Label column sm='3' xs='6' className='text-right'>
                  Bullseye Value
                </Form.Label>
                <Col sm='3' xs='4'>
                  <Form.Control
                    value={this.props.rules.bValue}
                    onChange={this.onChangeBValue}
                    type='number'
                  />
                </Col>
              </Form.Group>
            )}

            <Form.Group as={Row} controlId='formPlaintextEmail'>
              <Form.Label column sm='3' xs='6' className='text-right'>
                Trebles
              </Form.Label>
              <Col sm='9' xs='6' className='text-left m-auto'>
                <Form.Check
                  value={this.props.rules.trebles}
                  checked={this.props.rules.trebles}
                  onChange={this.onChangeSwitchRules}
                  type='switch'
                  label=''
                  id='custom-switch-trebles'
                  name='trebles'
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId='formPlaintextEmail'>
              <Form.Label column sm='3' xs='6' className='text-right'>
                Doubles
              </Form.Label>
              <Col sm='9' xs='6' className='text-left m-auto'>
                <Form.Check
                  value={this.props.rules.doubles}
                  checked={this.props.rules.doubles}
                  onChange={this.onChangeSwitchRules}
                  type='switch'
                  label=''
                  id='custom-switch-doubles'
                  name='doubles'
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId='formPlaintextEmail'>
              <Form.Label column sm='3' xs='6' className='text-right'>
                25
              </Form.Label>
              <Col sm='9' xs='6' className='text-left m-auto'>
                <Form.Check
                  value={this.props.rules.b25}
                  checked={this.props.rules.b25}
                  onChange={this.onChangeSwitchRules}
                  type='switch'
                  label=''
                  id='custom-switch-b25'
                  name='b25'
                />
              </Col>
            </Form.Group>
          </Card.Body>
        </Card>

        <Button
          variant='primary'
          className='w-100 mb-5'
          size='lg'
          onClick={this.startGame}
        >
          Start Game
        </Button>
      </div>
    );
  }
}

export default Setup;
