import React, { Component } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";

class Cricket extends Component {

  backToMenu = () => {
    this.props.updateSettings('add');
    this.props.updatePage('setup');
  }


  render() {

    let twentyTo = this.props.rules.twentyTo;

    let chart = [];
    for (let i = 20; i >= twentyTo; i--) {

      let hitsToScore = 'hitsToScore'+i;

      let scoreBoard =
        <Col
          key={i}
          xs="4"
          className="p-0"
        >

          <Container fluid>

            <Row style={{height: "12vh"}} className="p-1">

              <Col xs="6" className="border p-0">
                <h5
                  className="h-100 d-flex align-items-center justify-content-center"
                >
                  {i}
                </h5>
              </Col>

              <Col xs="6" className="border p-0">
                <div className="h-50 border d-flex align-items-center justify-content-center">
                  {this.props.game.playerOne.score[hitsToScore] - this.props.rules.hitsToScore < 0
                  ? 0 : this.props.game.playerOne.score[hitsToScore] - this.props.rules.hitsToScore}
                </div>
                <div className="h-50 border d-flex align-items-center justify-content-center">
                  {this.props.game.playerTwo.score[hitsToScore] - this.props.rules.hitsToScore < 0
                  ? 0 : this.props.game.playerTwo.score[hitsToScore] - this.props.rules.hitsToScore}
                </div>
              </Col>

            </Row>
          </Container>

        </Col>;

      chart.push(scoreBoard);
    }


    let winner;
    if (this.props.game.playerOne.score.totalScore === this.props.game.playerTwo.score.totalScore) {
      winner = 'Draw';
    } else if (this.props.game.playerOne.score.totalScore > this.props.game.playerTwo.score.totalScore){
      winner = `Winner: ${this.props.game.playerOne.name}`;
    } else {
      winner = `Winner: ${this.props.game.playerTwo.name}`;
    }



    return (

        <div className="mx-auto w-100">

          <div>
            <Container fluid>

              <Row className="sticky-top border-bottom text-white">
                <Col
                  xs="12"
                  className="bg-dark border-bottom"
                  style={{height: "10vh"}}
                >
                  <h5 className="h-100 d-flex align-items-center justify-content-center" style={{fontSize: "4vh"}}>
                    {winner}
                  </h5>
                </Col>

                <Col
                  xs="6"
                  className="bg-dark position-relative border-right"
                  style={{height: "12vh"}}
                >
                  <p className="mt-1 mb-1" style={{fontSize: "3vh"}}>
                    {this.props.game.playerOne.name}
                  </p>
                  <h5 className="m-0" style={{fontSize: "4vh"}}>{this.props.game.playerOne.score.totalScore}</h5>
                </Col>
                <Col
                  xs="6"
                  className="bg-dark position-relative"
                  style={{height: "12vh"}}
                >
                  <p className="mt-1 mb-1" style={{fontSize: "3vh"}}>
                    {this.props.game.playerTwo.name}
                  </p>
                  <h5 className="m-0" style={{fontSize: "4vh"}}>{this.props.game.playerTwo.score.totalScore}</h5>
                </Col>
              </Row>

              <Row className="mt-4 mx-auto">
        {chart}


        <Col
          xs="4"
          className="p-0"
        >
          <Container fluid>
            <Row style={{height: "12vh"}} className="p-1">
              <Col xs="6" className="border p-0">
                <h5
                  className="h-100 d-flex align-items-center justify-content-center"
                >
                  B
                </h5>
              </Col>
              <Col xs="6" className="border p-0">
                <div className="h-50 border d-flex align-items-center justify-content-center">
                  {this.props.game.playerOne.score.hitsToScoreB - this.props.rules.hitsToScore < 0
                    ? 0 : this.props.game.playerOne.score.hitsToScoreB - this.props.rules.hitsToScore}
                </div>
                <div className="h-50 border d-flex align-items-center justify-content-center">
                  {this.props.game.playerTwo.score.hitsToScoreB - this.props.rules.hitsToScore < 0
                    ? 0 : this.props.game.playerTwo.score.hitsToScoreB - this.props.rules.hitsToScore}
                </div>
              </Col>
            </Row>
          </Container>
        </Col>

        { this.props.rules.trebles === true &&
        <Col
          xs="4"
          className="p-0"
        >
          <Container fluid>
            <Row style={{height: "12vh"}} className="p-1">
              <Col xs="6" className="border p-0">
                <h5
                  className="h-100 d-flex align-items-center justify-content-center"
                >
                  T
                </h5>
              </Col>
              <Col xs="6" className="border p-0">
                <div className="h-50 border d-flex align-items-center justify-content-center">
                  {this.props.game.playerOne.score.hitsToScoreTrebles - this.props.rules.hitsToScore < 0
                    ? 0 : this.props.game.playerOne.score.hitsToScoreTrebles - this.props.rules.hitsToScore}
                </div>
                <div className="h-50 border d-flex align-items-center justify-content-center">
                  {this.props.game.playerTwo.score.hitsToScoreTrebles - this.props.rules.hitsToScore < 0
                    ? 0 : this.props.game.playerTwo.score.hitsToScoreTrebles - this.props.rules.hitsToScore}
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
        }

        { this.props.rules.doubles === true &&
        <Col
          xs="4"
          className="p-0"
        >
          <Container fluid>
            <Row style={{height: "12vh"}} className="p-1">
              <Col xs="6" className="border p-0">
                <h5
                  className="h-100 d-flex align-items-center justify-content-center"
                >
                  D
                </h5>
              </Col>
              <Col xs="6" className="border p-0">
                <div className="h-50 border d-flex align-items-center justify-content-center">
                  {this.props.game.playerOne.score.hitsToScoreDoubles - this.props.rules.hitsToScore < 0
                    ? 0 : this.props.game.playerOne.score.hitsToScoreDoubles - this.props.rules.hitsToScore}
                </div>
                <div className="h-50 border d-flex align-items-center justify-content-center">
                  {this.props.game.playerTwo.score.hitsToScoreDoubles - this.props.rules.hitsToScore < 0
                    ? 0 : this.props.game.playerTwo.score.hitsToScoreDoubles - this.props.rules.hitsToScore}
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
        }


        { this.props.rules.b25 === true &&
        <Col
          xs="4"
          className="p-0"
        >
          <Container fluid>
            <Row style={{height: "12vh"}} className="p-1">
              <Col xs="6" className="border p-0">
                <h5
                  className="h-100 d-flex align-items-center justify-content-center"
                >
                  B
                </h5>
              </Col>
              <Col xs="6" className="border p-0">
                <div className="h-50 border d-flex align-items-center justify-content-center">
                  {this.props.game.playerOne.score.hitsToScoreDoubles}
                </div>
                <div className="h-50 border d-flex align-items-center justify-content-center">
                  {this.props.game.playerTwo.score.hitsToScoreDoubles}
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
        }

        <Button
          variant="primary"
          className="w-100 my-5"
          size="lg"
          onClick={this.backToMenu}
        >
          Back to Menu
        </Button>

              </Row>

            </Container>
          </div>

      </div>
    );
  }
}

export default Cricket;