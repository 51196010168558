import React, { Component } from 'react';
import Setup from './components/setup';
import Cricket from './components/cricket';
import Results from './components/results';
import './App.css';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

class App extends Component {

  state = {
    page: 'setup',
    settings: 'add',
    type: 'single',
    rules: {
      hitsToScore: 3,
      twentyTo: 12,
      trebles: true,
      doubles: true,
      bValue: 25,
      b25: false
    },
    game: {
      activePlayer: 'playerOne',
      playerOne: {
        name: 'P1',
        score: {}
      },
      playerTwo: {
        name: 'P2',
        score: {}
      }
    }
  };


  updatePage = (page) => {
    this.setState({page})
  }

  updateRules = (rules) => {
    this.setState({rules})
  }

  updateGame = (game) => {
    this.setState({game})
  }

  updateSettings = (settings) => {
    this.setState({settings});
  }

  updateType = (type) => {
    this.setState({type});
  }


  render() {
    return (

      <div className="App"
           style={{MozUserSelect:"none", WebkitUserSelect:"none", msUserSelect:"none",}}
           unselectable="on"
           onselectstart="return false;"
           onmousedown="return false;"
           >

        <Container fluid style={{maxWidth: "700px", margin: "auto"}}>
          <Row>
        { this.state.page === 'setup' &&
          <Setup
            updatePage={this.updatePage}
            updateRules={this.updateRules}
            rules={this.state.rules}
            updateGame={this.updateGame}
            game={this.state.game}
          />
        }

        {this.state.page === 'cricket' &&
          <Cricket
            updatePage={this.updatePage}
            rules={this.state.rules}
            updateGame={this.updateGame}
            game={this.state.game}
            updateSettings={this.updateSettings}
            settings={this.state.settings}
            updateType={this.updateType}
            type={this.state.type}
          />
        }

        {this.state.page === 'results' &&
        <Results
          updatePage={this.updatePage}
          rules={this.state.rules}
          updateGame={this.updateGame}
          game={this.state.game}
          updateSettings={this.updateSettings}
          settings={this.state.settings}
        />
        }
          </Row>
        </Container>
      </div>
    );
  }
}

export default App;