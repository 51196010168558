import React, { Component } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";

class Cricket extends Component {
	updateHits = (e) => {
		let game = { ...this.props.game };
		let settings = this.props.settings;
		let type = this.props.type;
		let hitsToScore = this.props.rules.hitsToScore;
		let twentyTo = this.props.rules.twentyTo;

		let player = e.target.dataset.parent;
		let opponent = player === "playerOne" ? "playerTwo" : "playerOne";
		let hit = e.target.dataset.hit;
		let score = parseInt(e.target.dataset.score);

		if (settings === "add") {
			let numberOfHits = game[player].score[hit];

			// Increase hits
			if (
				(numberOfHits < hitsToScore ||
					game[opponent].score[hit] < hitsToScore) &&
				type === "single" &&
				(score >= twentyTo || score === 0)
			) {
				game[player].score[hit] = ++numberOfHits;
			}

			// Increase Score
			if (
				numberOfHits > hitsToScore &&
				game[opponent].score[hit] < hitsToScore &&
				type === "single" &&
				(score >= twentyTo || score === 0)
			) {
				game[player].score.totalScore += score;

				// multipliers
				if (hit === "hitsToScoreDoubles") {
					this.props.updateType("double");
					this.updateMultipliersData(player, hit);
				} else if (hit === "hitsToScoreTrebles") {
					this.updateMultipliersData(player, hit);
					this.props.updateType("treble");
				}
			} else if (type === "double") {
				game[player].score.totalScore += score * 2;
				this.props.updateType("single");
			} else if (type === "treble") {
				game[player].score.totalScore += score * 3;
				this.props.updateType("single");
			}
		}

		if (settings === "remove") {
			let numberOfHits = game[player].score[hit];

			// Decrease hits
			if (numberOfHits > 0 && type === "single") {
				game[player].score[hit] = --numberOfHits;
			}

			// Decrease Score
			if (numberOfHits >= hitsToScore && type === "single") {
				game[player].score.totalScore -= score;

				// multipliers
				if (numberOfHits >= hitsToScore && hit === "hitsToScoreDoubles") {
					this.props.updateType("double");
					this.updateMultipliersData(player, hit);
				} else if (
					numberOfHits >= hitsToScore &&
					hit === "hitsToScoreTrebles"
				) {
					this.props.updateType("treble");
					this.updateMultipliersData(player, hit);
				}
			} else if (type === "double") {
				game[player].score.totalScore -= score * 2;
				this.props.updateType("single");
			} else if (type === "treble") {
				game[player].score.totalScore -= score * 3;
				this.props.updateType("single");
			}
		}

		this.props.updateGame(game);
	};

	updateMultipliersData = (parent, hit) => {
		let multiplers = document.querySelectorAll(".multipler-score");

		for (let i = 20; i >= 0; i--) {
			multiplers[i].dataset.parent = parent;
			multiplers[i].dataset.hit = hit;
		}
	};

	updateActivePlayer = (newActivePlayer) => {
		let game = { ...this.props.game };
		game.activePlayer = newActivePlayer;
		this.props.updateGame(game);
	};

	toggleSettings = () => {
		let settings = this.props.settings === "add" ? "remove" : "add";
		this.props.updateSettings(settings);
	};

	cancelMultiplier = (e) => {
		let game = { ...this.props.game };
		let settings = this.props.settings;

		let player = e.target.dataset.parent;
		let hit = e.target.dataset.hit;

		let numberOfHits = game[player].score[hit];

		if (settings === "add") {
			game[player].score[hit] = --numberOfHits;
		} else if (settings === "remove") {
			game[player].score[hit] = ++numberOfHits;
		}

		this.props.updateType("single");
	};

	endGame = () => {
		this.props.updatePage("results");
	};

	render() {
		// Single scoring options
		let twentyTo = this.props.rules.twentyTo;

		let chart = [];
		for (let i = 20; i >= twentyTo; i--) {
			let hitsToScore = "hitsToScore" + i;
			let activePlayer = this.props.game.activePlayer;
			let opponent =
				this.props.game.activePlayer === "playerOne"
					? "playerTwo"
					: "playerOne";

			let numberOfHits = "";
			if (i >= twentyTo) {
				numberOfHits = (
					<Col xs="6" className="border p-0">
						<div
							className={
								"h-50 border d-flex align-items-center justify-content-center " +
								(this.props.game.playerOne.score[hitsToScore] >=
									this.props.rules.hitsToScore &&
								this.props.game.playerTwo.score[hitsToScore] <
									this.props.rules.hitsToScore
									? "bg-success text-white"
									: "") +
								(this.props.game.playerOne.score[hitsToScore] <
									this.props.rules.hitsToScore &&
								this.props.game.playerTwo.score[hitsToScore] >=
									this.props.rules.hitsToScore
									? "bg-warning text-white"
									: "") +
								(this.props.game.playerOne.score[hitsToScore] >=
									this.props.rules.hitsToScore &&
								this.props.game.playerTwo.score[hitsToScore] >=
									this.props.rules.hitsToScore
									? "bg-danger text-white"
									: "")
							}
						>
							{this.props.game.playerOne.score[hitsToScore]}
						</div>
						<div
							className={
								"h-50 border d-flex align-items-center justify-content-center " +
								(this.props.game.playerTwo.score[hitsToScore] >=
									this.props.rules.hitsToScore &&
								this.props.game.playerOne.score[hitsToScore] <
									this.props.rules.hitsToScore
									? "bg-success text-white"
									: "") +
								(this.props.game.playerTwo.score[hitsToScore] <
									this.props.rules.hitsToScore &&
								this.props.game.playerOne.score[hitsToScore] >=
									this.props.rules.hitsToScore
									? "bg-warning text-white"
									: "") +
								(this.props.game.playerTwo.score[hitsToScore] >=
									this.props.rules.hitsToScore &&
								this.props.game.playerOne.score[hitsToScore] >=
									this.props.rules.hitsToScore
									? "bg-danger text-white"
									: "")
							}
						>
							{this.props.game.playerTwo.score[hitsToScore]}
						</div>
					</Col>
				);
			}

			let scoreOption = (
				<Col key={i} xs="4" className="p-0">
					<Container fluid>
						<Row style={{ height: "12vh" }} className="p-1">
							<Col xs="6" className="border p-0">
								<h5
									className={
										"h-100 d-flex align-items-center justify-content-center " +
										(this.props.game[activePlayer].score[hitsToScore] >=
											this.props.rules.hitsToScore &&
										this.props.game[opponent].score[hitsToScore] <
											this.props.rules.hitsToScore
											? "bg-success text-white"
											: "") +
										(this.props.game[activePlayer].score[hitsToScore] <
											this.props.rules.hitsToScore &&
										this.props.game[opponent].score[hitsToScore] >=
											this.props.rules.hitsToScore
											? "bg-warning text-white"
											: "") +
										(this.props.game[activePlayer].score[hitsToScore] >=
											this.props.rules.hitsToScore &&
										this.props.game[opponent].score[hitsToScore] >=
											this.props.rules.hitsToScore
											? "bg-danger text-white"
											: "")
									}
									data-parent={this.props.game.activePlayer}
									data-hit={hitsToScore}
									data-score={i}
									onClick={this.updateHits}
								>
									{i}
								</h5>
							</Col>

							{numberOfHits}
						</Row>
					</Container>
				</Col>
			);

			chart.push(scoreOption);
		}

		// Multiplier scoring options
		let multipliers = [];
		for (let i = 20; i >= 1; i--) {
			let hitsToScore = "hitsToScore" + i;

			let col = (
				<Col xs="4" key={i}>
					<Card className="p-2 mb-3">
						<Card.Title className="mb-0">
							<h5
								className="multipler-score my-1 h-100 d-flex align-items-center justify-content-center"
								data-parent={this.props.game.activePlayer}
								data-hit={hitsToScore}
								data-score={i}
								onClick={this.updateHits}
							>
								{i}
							</h5>
						</Card.Title>
					</Card>
				</Col>
			);

			multipliers.push(col);
		}

		return (
			<div className="mx-auto w-100">
				<div>
					<Container fluid>
						<Row className="sticky-top border-bottom text-white">
							<Col
								xs="6"
								className="bg-dark"
								onClick={() => this.updateActivePlayer("playerOne")}
								style={{
									height: "12vh",
									opacity:
										this.props.game.activePlayer === "playerOne" ? "1" : "0.9",
								}}
							>
								{this.props.game.activePlayer === "playerOne" ? (
									<span
										className="bg-success position-absolute rounded-circle m-2 "
										style={{ height: "10px", width: "10px", right: "0" }}
									></span>
								) : (
									""
								)}
								<p className="mt-1 mb-1" style={{ fontSize: "3vh" }}>
									{this.props.game.playerOne.name}
								</p>
								<h5 className="m-0" style={{ fontSize: "4vh" }}>
									{this.props.game.playerOne.score.totalScore}
								</h5>
							</Col>
							<Col
								xs="6"
								className="bg-dark position-relative"
								onClick={() => this.updateActivePlayer("playerTwo")}
								style={{
									height: "12vh",
									opacity:
										this.props.game.activePlayer === "playerTwo" ? "1" : "0.9",
								}}
							>
								{this.props.game.activePlayer === "playerTwo" ? (
									<span
										className="bg-success position-absolute rounded-circle m-2 "
										style={{ height: "10px", width: "10px", right: "0" }}
									></span>
								) : (
									""
								)}
								<p className="mt-1 mb-1" style={{ fontSize: "3vh" }}>
									{this.props.game.playerTwo.name}
								</p>
								<h5 className="m-0" style={{ fontSize: "4vh" }}>
									{this.props.game.playerTwo.score.totalScore}
								</h5>
							</Col>
						</Row>
					</Container>
				</div>

				<div className={this.props.type === "single" ? "" : "d-none"}>
					<Container fluid>
						<Row className="mt-4 mx-auto">
							{chart}

							<Col xs="4" className="p-0">
								<Container fluid>
									<Row style={{ height: "12vh" }} className="p-1">
										<Col xs="6" className="border p-0">
											<h5
												className={
													"h-100 d-flex align-items-center justify-content-center " +
													(this.props.game.activePlayer === "playerOne" &&
													this.props.game.playerOne.score.hitsToScoreB >=
														this.props.rules.hitsToScore &&
													this.props.game.playerTwo.score.hitsToScoreB <
														this.props.rules.hitsToScore
														? "bg-success text-white"
														: "") +
													(this.props.game.activePlayer === "playerOne" &&
													this.props.game.playerOne.score.hitsToScoreB <
														this.props.rules.hitsToScore &&
													this.props.game.playerTwo.score.hitsToScoreB >=
														this.props.rules.hitsToScore
														? "bg-warning text-white"
														: "") +
													(this.props.game.activePlayer === "playerTwo" &&
													this.props.game.playerTwo.score.hitsToScoreB >=
														this.props.rules.hitsToScore &&
													this.props.game.playerOne.score.hitsToScoreB <
														this.props.rules.hitsToScore
														? "bg-success text-white"
														: "") +
													(this.props.game.activePlayer === "playerTwo" &&
													this.props.game.playerTwo.score.hitsToScoreB <
														this.props.rules.hitsToScore &&
													this.props.game.playerOne.score.hitsToScoreB >=
														this.props.rules.hitsToScore
														? "bg-warning text-white"
														: "") +
													(this.props.game.playerOne.score.hitsToScoreB >=
														this.props.rules.hitsToScore &&
													this.props.game.playerTwo.score.hitsToScoreB >=
														this.props.rules.hitsToScore
														? "bg-danger text-white"
														: "")
												}
												data-parent={this.props.game.activePlayer}
												data-hit="hitsToScoreB"
												data-score={this.props.rules.bValue}
												onClick={this.updateHits}
											>
												B
											</h5>
										</Col>

										<Col xs="6" className="border p-0">
											<div
												className={
													"h-50 border d-flex align-items-center justify-content-center " +
													(this.props.game.playerOne.score.hitsToScoreB >=
														this.props.rules.hitsToScore &&
													this.props.game.playerTwo.score.hitsToScoreB <
														this.props.rules.hitsToScore
														? "bg-success text-white"
														: "") +
													(this.props.game.playerOne.score.hitsToScoreB <
														this.props.rules.hitsToScore &&
													this.props.game.playerTwo.score.hitsToScoreB >=
														this.props.rules.hitsToScore
														? "bg-warning text-white"
														: "") +
													(this.props.game.playerOne.score.hitsToScoreB >=
														this.props.rules.hitsToScore &&
													this.props.game.playerTwo.score.hitsToScoreB >=
														this.props.rules.hitsToScore
														? "bg-danger text-white"
														: "")
												}
											>
												{this.props.game.playerOne.score.hitsToScoreB}
											</div>
											<div
												className={
													"h-50 border d-flex align-items-center justify-content-center " +
													(this.props.game.playerTwo.score.hitsToScoreB >=
														this.props.rules.hitsToScore &&
													this.props.game.playerOne.score.hitsToScoreB <
														this.props.rules.hitsToScore
														? "bg-success text-white"
														: "") +
													(this.props.game.playerTwo.score.hitsToScoreB <
														this.props.rules.hitsToScore &&
													this.props.game.playerOne.score.hitsToScoreB >=
														this.props.rules.hitsToScore
														? "bg-warning text-white"
														: "") +
													(this.props.game.playerTwo.score.hitsToScoreB >=
														this.props.rules.hitsToScore &&
													this.props.game.playerOne.score.hitsToScoreB >=
														this.props.rules.hitsToScore
														? "bg-danger text-white"
														: "")
												}
											>
												{this.props.game.playerTwo.score.hitsToScoreB}
											</div>
										</Col>
									</Row>
								</Container>
							</Col>

							{this.props.rules.trebles === true && (
								<Col xs="4" className="p-0">
									<Container fluid>
										<Row style={{ height: "12vh" }} className="p-1">
											<Col xs="6" className="border p-0">
												<h5
													className={
														"h-100 d-flex align-items-center justify-content-center " +
														(this.props.game.activePlayer === "playerOne" &&
														this.props.game.playerOne.score
															.hitsToScoreTrebles >=
															this.props.rules.hitsToScore &&
														this.props.game.playerTwo.score.hitsToScoreTrebles <
															this.props.rules.hitsToScore
															? "bg-success text-white"
															: "") +
														(this.props.game.activePlayer === "playerOne" &&
														this.props.game.playerOne.score.hitsToScoreTrebles <
															this.props.rules.hitsToScore &&
														this.props.game.playerTwo.score
															.hitsToScoreTrebles >=
															this.props.rules.hitsToScore
															? "bg-warning text-white"
															: "") +
														(this.props.game.activePlayer === "playerTwo" &&
														this.props.game.playerTwo.score
															.hitsToScoreTrebles >=
															this.props.rules.hitsToScore &&
														this.props.game.playerOne.score.hitsToScoreTrebles <
															this.props.rules.hitsToScore
															? "bg-success text-white"
															: "") +
														(this.props.game.activePlayer === "playerTwo" &&
														this.props.game.playerTwo.score.hitsToScoreTrebles <
															this.props.rules.hitsToScore &&
														this.props.game.playerOne.score
															.hitsToScoreTrebles >=
															this.props.rules.hitsToScore
															? "bg-warning text-white"
															: "") +
														(this.props.game.playerOne.score
															.hitsToScoreTrebles >=
															this.props.rules.hitsToScore &&
														this.props.game.playerTwo.score
															.hitsToScoreTrebles >=
															this.props.rules.hitsToScore
															? "bg-danger text-white"
															: "")
													}
													data-parent={this.props.game.activePlayer}
													data-hit="hitsToScoreTrebles"
													data-score="0"
													onClick={this.updateHits}
												>
													T
												</h5>
											</Col>

											<Col xs="6" className="border p-0">
												<div
													className={
														"h-50 border d-flex align-items-center justify-content-center " +
														(this.props.game.playerOne.score
															.hitsToScoreTrebles >=
															this.props.rules.hitsToScore &&
														this.props.game.playerTwo.score.hitsToScoreTrebles <
															this.props.rules.hitsToScore
															? "bg-success text-white"
															: "") +
														(this.props.game.playerOne.score
															.hitsToScoreTrebles <
															this.props.rules.hitsToScore &&
														this.props.game.playerTwo.score
															.hitsToScoreTrebles >=
															this.props.rules.hitsToScore
															? "bg-warning text-white"
															: "") +
														(this.props.game.playerOne.score
															.hitsToScoreTrebles >=
															this.props.rules.hitsToScore &&
														this.props.game.playerTwo.score
															.hitsToScoreTrebles >=
															this.props.rules.hitsToScore
															? "bg-danger text-white"
															: "")
													}
												>
													{this.props.game.playerOne.score.hitsToScoreTrebles}
												</div>
												<div
													className={
														"h-50 border d-flex align-items-center justify-content-center " +
														(this.props.game.playerTwo.score
															.hitsToScoreTrebles >=
															this.props.rules.hitsToScore &&
														this.props.game.playerOne.score.hitsToScoreTrebles <
															this.props.rules.hitsToScore
															? "bg-success text-white"
															: "") +
														(this.props.game.playerTwo.score
															.hitsToScoreTrebles <
															this.props.rules.hitsToScore &&
														this.props.game.playerOne.score
															.hitsToScoreTrebles >=
															this.props.rules.hitsToScore
															? "bg-warning text-white"
															: "") +
														(this.props.game.playerTwo.score
															.hitsToScoreTrebles >=
															this.props.rules.hitsToScore &&
														this.props.game.playerOne.score
															.hitsToScoreTrebles >=
															this.props.rules.hitsToScore
															? "bg-danger text-white"
															: "")
													}
												>
													{this.props.game.playerTwo.score.hitsToScoreTrebles}
												</div>
											</Col>
										</Row>
									</Container>
								</Col>
							)}

							{this.props.rules.doubles === true && (
								<Col xs="4" className="p-0">
									<Container fluid>
										<Row style={{ height: "12vh" }} className="p-1">
											<Col xs="6" className="border p-0">
												<h5
													className={
														"h-100 d-flex align-items-center justify-content-center " +
														(this.props.game.activePlayer === "playerOne" &&
														this.props.game.playerOne.score
															.hitsToScoreDoubles >=
															this.props.rules.hitsToScore &&
														this.props.game.playerTwo.score.hitsToScoreDoubles <
															this.props.rules.hitsToScore
															? "bg-success text-white"
															: "") +
														(this.props.game.activePlayer === "playerOne" &&
														this.props.game.playerOne.score.hitsToScoreDoubles <
															this.props.rules.hitsToScore &&
														this.props.game.playerTwo.score
															.hitsToScoreDoubles >=
															this.props.rules.hitsToScore
															? "bg-warning text-white"
															: "") +
														(this.props.game.activePlayer === "playerTwo" &&
														this.props.game.playerTwo.score
															.hitsToScoreDoubles >=
															this.props.rules.hitsToScore &&
														this.props.game.playerOne.score.hitsToScoreDoubles <
															this.props.rules.hitsToScore
															? "bg-success text-white"
															: "") +
														(this.props.game.activePlayer === "playerTwo" &&
														this.props.game.playerTwo.score.hitsToScoreDoubles <
															this.props.rules.hitsToScore &&
														this.props.game.playerOne.score
															.hitsToScoreDoubles >=
															this.props.rules.hitsToScore
															? "bg-warning text-white"
															: "") +
														(this.props.game.playerOne.score
															.hitsToScoreDoubles >=
															this.props.rules.hitsToScore &&
														this.props.game.playerTwo.score
															.hitsToScoreDoubles >=
															this.props.rules.hitsToScore
															? "bg-danger text-white"
															: "")
													}
													data-parent={this.props.game.activePlayer}
													data-hit="hitsToScoreDoubles"
													data-score="0"
													onClick={this.updateHits}
												>
													D
												</h5>
											</Col>

											<Col xs="6" className="border p-0">
												<div
													className={
														"h-50 border d-flex align-items-center justify-content-center " +
														(this.props.game.playerOne.score
															.hitsToScoreDoubles >=
															this.props.rules.hitsToScore &&
														this.props.game.playerTwo.score.hitsToScoreDoubles <
															this.props.rules.hitsToScore
															? "bg-success text-white"
															: "") +
														(this.props.game.playerOne.score
															.hitsToScoreDoubles <
															this.props.rules.hitsToScore &&
														this.props.game.playerTwo.score
															.hitsToScoreDoubles >=
															this.props.rules.hitsToScore
															? "bg-warning text-white"
															: "") +
														(this.props.game.playerOne.score
															.hitsToScoreDoubles >=
															this.props.rules.hitsToScore &&
														this.props.game.playerTwo.score
															.hitsToScoreDoubles >=
															this.props.rules.hitsToScore
															? "bg-danger text-white"
															: "")
													}
												>
													{this.props.game.playerOne.score.hitsToScoreDoubles}
												</div>
												<div
													className={
														"h-50 border d-flex align-items-center justify-content-center " +
														(this.props.game.playerTwo.score
															.hitsToScoreDoubles >=
															this.props.rules.hitsToScore &&
														this.props.game.playerOne.score.hitsToScoreDoubles <
															this.props.rules.hitsToScore
															? "bg-success text-white"
															: "") +
														(this.props.game.playerTwo.score
															.hitsToScoreDoubles <
															this.props.rules.hitsToScore &&
														this.props.game.playerOne.score
															.hitsToScoreDoubles >=
															this.props.rules.hitsToScore
															? "bg-warning text-white"
															: "") +
														(this.props.game.playerTwo.score
															.hitsToScoreDoubles >=
															this.props.rules.hitsToScore &&
														this.props.game.playerOne.score
															.hitsToScoreDoubles >=
															this.props.rules.hitsToScore
															? "bg-danger text-white"
															: "")
													}
												>
													{this.props.game.playerTwo.score.hitsToScoreDoubles}
												</div>
											</Col>
										</Row>
									</Container>
								</Col>
							)}

							{this.props.rules.b25 === true && (
								<Col xs="4" className="p-0">
									<Container fluid>
										<Row style={{ height: "12vh" }} className="p-1">
											<Col xs="6" className="border p-0">
												<h5
													className={
														"h-100 d-flex align-items-center justify-content-center " +
														(this.props.game.activePlayer === "playerOne" &&
														this.props.game.playerOne.score.hitsToScoreB25 >=
															this.props.rules.hitsToScore &&
														this.props.game.playerTwo.score.hitsToScoreB25 <
															this.props.rules.hitsToScore
															? "bg-success text-white"
															: "") +
														(this.props.game.activePlayer === "playerOne" &&
														this.props.game.playerOne.score.hitsToScoreB25 <
															this.props.rules.hitsToScore &&
														this.props.game.playerTwo.score.hitsToScoreB25 >=
															this.props.rules.hitsToScore
															? "bg-warning text-white"
															: "") +
														(this.props.game.activePlayer === "playerTwo" &&
														this.props.game.playerTwo.score.hitsToScoreB25 >=
															this.props.rules.hitsToScore &&
														this.props.game.playerOne.score.hitsToScoreB25 <
															this.props.rules.hitsToScore
															? "bg-success text-white"
															: "") +
														(this.props.game.activePlayer === "playerTwo" &&
														this.props.game.playerTwo.score.hitsToScoreB25 <
															this.props.rules.hitsToScore &&
														this.props.game.playerOne.score.hitsToScoreB25 >=
															this.props.rules.hitsToScore
															? "bg-warning text-white"
															: "") +
														(this.props.game.playerOne.score.hitsToScoreB25 >=
															this.props.rules.hitsToScore &&
														this.props.game.playerTwo.score.hitsToScoreB25 >=
															this.props.rules.hitsToScore
															? "bg-danger text-white"
															: "")
													}
													data-parent={this.props.game.activePlayer}
													data-hit="hitsToScoreB25"
													data-score="25"
													onClick={this.updateHits}
												>
													25
												</h5>
											</Col>

											<Col xs="6" className="border p-0">
												<div
													className={
														"h-50 border d-flex align-items-center justify-content-center " +
														(this.props.game.playerOne.score.hitsToScoreB25 >=
															this.props.rules.hitsToScore &&
														this.props.game.playerTwo.score.hitsToScoreB25 <
															this.props.rules.hitsToScore
															? "bg-success text-white"
															: "") +
														(this.props.game.playerOne.score.hitsToScoreB25 <
															this.props.rules.hitsToScore &&
														this.props.game.playerTwo.score.hitsToScoreB25 >=
															this.props.rules.hitsToScore
															? "bg-warning text-white"
															: "") +
														(this.props.game.playerOne.score.hitsToScoreB25 >=
															this.props.rules.hitsToScore &&
														this.props.game.playerTwo.score.hitsToScoreB25 >=
															this.props.rules.hitsToScore
															? "bg-danger text-white"
															: "")
													}
												>
													{this.props.game.playerOne.score.hitsToScoreB25}
												</div>
												<div
													className={
														"h-50 border d-flex align-items-center justify-content-center " +
														(this.props.game.playerTwo.score.hitsToScoreB25 >=
															this.props.rules.hitsToScore &&
														this.props.game.playerOne.score.hitsToScoreB25 <
															this.props.rules.hitsToScore
															? "bg-success text-white"
															: "") +
														(this.props.game.playerTwo.score.hitsToScoreB25 <
															this.props.rules.hitsToScore &&
														this.props.game.playerOne.score.hitsToScoreB25 >=
															this.props.rules.hitsToScore
															? "bg-warning text-white"
															: "") +
														(this.props.game.playerTwo.score.hitsToScoreB25 >=
															this.props.rules.hitsToScore &&
														this.props.game.playerOne.score.hitsToScoreB25 >=
															this.props.rules.hitsToScore
															? "bg-danger text-white"
															: "")
													}
												>
													{this.props.game.playerTwo.score.hitsToScoreB25}
												</div>
											</Col>
										</Row>
									</Container>
								</Col>
							)}
						</Row>

						<Row className="mt-4 mx-auto">
							<Col xs="4" className="p-0">
								<Container fluid>
									<Row style={{ height: "10vh" }} className="p-1">
										<Col
											xs="12"
											className="p-0 bg-danger btn btn-lg text-white"
										>
											<h5
												className="h-100 d-flex align-items-center justify-content-center"
												onClick={this.endGame}
											>
												End
											</h5>
										</Col>
									</Row>
								</Container>
							</Col>

							<Col xs="4" className="p-0">
								<Container fluid>
									<Row style={{ height: "10vh" }} className="p-1">
										<Col xs="12" className="p-0">
											<h5
												className={
													"btn btn-lg h-100 d-flex align-items-center justify-content-center text-white " +
													(this.props.settings === "add"
														? "bg-success"
														: "bg-danger")
												}
												onClick={this.toggleSettings}
											>
												{this.props.settings === "add" ? "+" : "-"}
											</h5>
										</Col>
									</Row>
								</Container>
							</Col>

							<Col xs="4" className="p-0">
								<Container fluid>
									<Row style={{ height: "10vh" }} className="p-1">
										<Col
											xs="12"
											className="p-0 bg-primary btn btn-lg text-white"
											onClick={() =>
												this.props.game.activePlayer === "playerOne"
													? this.updateActivePlayer("playerTwo")
													: this.updateActivePlayer("playerOne")
											}
										>
											<h5 className="h-100 d-flex align-items-center justify-content-center">
												Next
											</h5>
										</Col>
									</Row>
								</Container>
							</Col>
						</Row>
					</Container>
				</div>

				<div className={this.props.type === "single" ? "d-none" : ""}>
					<Container fluid>
						<Row className="w-100 mx-auto mt-3">
							{multipliers}

							<Col xs="4">
								<Card className="p-2 mb-3 bg-danger">
									<Card.Title className="mb-0">
										<h5
											className="multipler-score my-1 h-100 d-flex align-items-center justify-content-center text-white"
											data-parent=""
											data-hit=""
											onClick={this.cancelMultiplier}
										>
											X
										</h5>
									</Card.Title>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		);
	}
}

export default Cricket;
